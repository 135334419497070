/* Base styles */
body {
  font-family: 'Special Elite', cursive;
  line-height: 1.6;
  color: #333;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f4f4;
}

.App {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

h2 {
  color: #2c3e50;
  border-bottom: 2px solid #3498db;
  padding-bottom: 10px;
}

/* Button styles */
button {
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 5px 0;
}

button:hover {
  background-color: #2980b9;
}

/* Form elements */
select, input[type="text"] {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
}

/* Game history styles */
.game-history {
  background-color: #ecf0f1;
  border-radius: 8px;
  padding: 15px;
  margin-top: 20px;
}

.history-round {
  background-color: #fff;
  border: 1px solid #bdc3c7;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

.history-round h4 {
  color: #2c3e50;
  margin-top: 0;
}

/* Responsive design */
@media (max-width: 600px) {
  body {
    padding: 10px;
  }

  .App {
    padding: 15px;
  }

  h2 {
    font-size: 1.5em;
  }

  button {
    width: 100%;
    margin: 5px 0;
  }
}

/* Additional styles for Player component */
.player-info {
  background-color: #e8f4fd;
  border-left: 4px solid #3498db;
  padding: 10px;
  margin-bottom: 15px;
}

.story-phrase {
  font-style: italic;
  color: #2c3e50;
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0;
}

.voting-section {
  background-color: #f0f7fa;
  border-radius: 8px;
  padding: 15px;
  margin-top: 20px;
}

.voting-status {
  list-style-type: none;
  padding: 0;
}

.voting-status li {
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
}

.voting-status li:last-child {
  border-bottom: none;
}

.error-message {
  color: #e74c3c;
  background-color: #fadbd8;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
}

.success-message {
  color: #27ae60;
  background-color: #d4efdf;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
}
