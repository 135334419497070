/* Styles for How to Play page */

.how-to-play-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Special Elite', cursive;
}

.how-to-play-container h1,
.how-to-play-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.how-to-play-container p {
  font-size: 18px;
  line-height: 1.6;
}

.how-to-play-container ol,
.how-to-play-container ul {
  font-size: 18px;
  margin-left: 20px;
}

.how-to-play-container li {
  margin-bottom: 10px;
}

/* Shared styles for the back button */
.back-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  margin-top: 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Special Elite', cursive;
  font-size: 16px;
}

.back-button:hover {
  background-color: #45a049;
}
