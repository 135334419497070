/* GameSetup.css */

body {
    font-family: 'Special Elite', cursive;
  }
  
  .game-setup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
    background-color: transparent;
    max-width: 600px;
    margin: 0 auto;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .game-setup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 600px;
  }
  
  .bubble-image {
    width: 100%;
    max-width: 300px;
    height: auto;
    margin-bottom: 20px;
  }
  
  .button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  /* Updated button styles */
  .start-button,
  .secondary-button,
  .game-setup-form button,
  .copy-button {
    background-color: #3498db;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 5px 0;
    font-family: 'Special Elite', cursive;
  }
  
  .start-button:hover,
  .secondary-button:hover,
  .game-setup-form button:hover,
  .copy-button:hover {
    background-color: #2980b9;
  }
  
  .start-button,
  .secondary-button {
    padding: 15px 30px;
    margin: 10px;
    font-size: 18px;
    width: 200px;
  }
  
  .comic-logo-image {
    width: 100%;
    max-width: 400px;
    height: auto;
    margin-top: 20px;
  }
  
  .game-setup-form {
    width: 100%;
    padding: 20px;
  }
  
  .game-setup-form h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .game-setup-form form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .game-setup-form label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .game-setup-form input[type="text"],
  .game-setup-form input[type="number"],
  .game-setup-form select {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .game-setup-form button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .game-setup-form button:hover {
    background-color: #45a049;
  }
  
  .game-created {
    margin-top: 30px;
    padding: 15px;
    background-color: #f0f0f0;
    border-radius: 5px;
    width: 100%;
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
  }
  
  .down-arrow {
    font-size: 24px;
    color: #3498db; /* Updated to match button color */
    text-align: center;
    margin: 20px 0;
    animation: bounce 2s infinite;
  }
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }
  
  .copy-button {
    background-color: #3498db;
    padding: 5px 10px;
    margin-left: 10px;
    display: inline-flex;
    align-items: center;
    font-size: 12px;
  }
  
  .copy-button:hover {
    background-color: #2980b9;
  }
  
  .copy-button .MuiSvgIcon-root {
    margin-right: 5px;
  }
  
  .game-created ul {
    list-style-type: none;
    padding: 0;
  }
  
  .game-created li {
    margin-bottom: 15px;
  }
  
  .game-created a {
    word-break: break-all;
  }
  
  @media (max-width: 600px) {
    .game-setup-content {
      width: 100%;
      padding: 20px;
    }
    
    .start-button,
    .secondary-button,
    .game-setup-form button {
      width: 100%;
      margin: 5px 0;
    }
  }
  
  .advanced-options {
    margin-top: 20px;
    padding: 15px;
    background-color: #e9e9e9;
    border-radius: 4px;
  }
