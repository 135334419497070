.game-history {
    margin-top: 20px;
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 5px;
  }
  
  .history-round {
    margin-bottom: 10px;
    padding: 10px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .history-round h4 {
    margin-top: 0;
    color: #333;
  }

/* Player.css */

/* Container for the player page */
.player-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  font-family: 'Special Elite', cursive;
}

/* Specific styles for headings and text */
.player-container h1,
.player-container h2,
.player-container h3,
.player-container p {
  font-family: 'Special Elite', cursive;
}

/* Styling for the header image */
.player-header-image {
  width: 100%;
  max-width: 600px;
  height: auto;
  display: block;
  margin: 0 auto 20px auto;
}

body {
  font-family: 'Special Elite', cursive;
}

/* For headings */
h1, h2, h3 {
  font-family: 'Special Elite', cursive;
}

/* For paragraphs */
p {
  font-family: 'Special Elite', cursive;
}

/* Existing button styles */
button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  margin-top: 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Special Elite', cursive;
  font-size: 16px;
}

/* Add hover effect */
button:hover {
  background-color: #45a049;
}

/* Styles for Your Story Phrase section */

.your-story-phrase {
  margin-top: 20px;
  text-align: center;
}

.your-story-phrase p {
  margin: 0; /* Remove default margins */
}

.your-story-phrase p.story-phrase {
  font-size: 2em; /* Match the font size of your heading */
  font-weight: bold;
  margin-top: 10px;
}

/* Style for the voting select dropdown */
.voting-section select {
  font-size: 1.2em; /* Increase as needed */
  padding: 5px;
  margin-bottom: 10px;
}

/* Optional: Increase the font size of the option elements */
.voting-section select option {
  font-size: 1.2em;
}

/* Styling for the dead image */
.dead-image {
  width: 100%;
  height: auto;
  display: block;
  margin: 10px auto 0 auto;
}

/* Styling for the 'Got the StoryKiller' image */
.got-storykiller-image {
  width: 100%;
  height: auto;
  display: block;
  margin: 20px auto;
}

.storykiller-text {
  font-size: 1.2em;
  font-weight: bold;
}

.storykiller-text.is-storykiller {
  color: darkred;
}

.storykiller-text.not-storykiller {
  color: #31b2e4;
}

/* Responsive image styling */
@media (max-width: 600px) {
  .got-storykiller-image {
    width: 100%; /* Adjust as needed */
  }
}

/* Styling for the StoryKiller Wins image */
.storykiller-wins-image {
  width: 100%; /* Semicolon added here */
  height: auto;
  display: block;
  margin: 20px auto;
}

/* Styles for the cover container */
.cover-container {
  text-align: center;
  margin: 20px 0;
}

/* Styles for the content that holds the cover image or reveal content */
.cover-content {
  position: relative;
  display: inline-block;
}

/* Styles for the cover image */
.cover-image {
  width: 300px; /* Adjust the width as needed */
  height: auto;
  cursor: pointer;
}

/* Styles for the content revealed after clicking */
.reveal-content {
  margin-top: 20px;
  font-size: 1.2em;
  text-align: center;
}